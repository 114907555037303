import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Tokenomics from './components/Tokenomics';
import Socials from './components/Socials'; // Import the Socials component
// import './App.css';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <Tokenomics />
      <Socials /> {/* Add the Socials component here */}
      {/* Add other components as needed */}
    </div>
  );
};

export default App;
