import React from 'react';
import './Socials.css'; // Make sure this path is correct to apply your styles
import twitterLogo from './Twitter X.png'; // Adjust the path to where your Twitter logo is saved
import telegramLogo from './Untitled.png'; // Adjust the path to where your Telegram logo is saved

const Socials = () => {
  return (
    <section id="socials" className="socials">
      <div className="socials-content">
        <p>Join my community on:</p>
        <div className="socials-cards">
          <div className="social-card">
            <a href="https://x.com/NiggaCatonSol/status/1774464492921143730?s=20" target="_blank" rel="noopener noreferrer">
              <img src={twitterLogo} alt="Twitter" />
            </a>
          </div>
          <div className="social-card">
            <a href="https://t.me/niggacatportal" target="_blank" rel="noopener noreferrer">
              <img src={telegramLogo} alt="Telegram" />
            </a>
          </div>
        </div>
      </div>
      {/* Banner with animated text */}
      {/* <div className="bottom-banner">
        <div className="animate-marquee">
          <span>
            Join our community__Join our community__Join our community__Join our community__Join our community__Join our community__Join our community__Join our community__
          </span>
        </div>
      </div> */}
    </section>
  );
};

export default Socials;
