import React from 'react';
const bgImagePath = './lol.png'; // Ensure the path is correct based on your project structure

const Tokenomics = () => {
  return (
    <section
      id="tokenomics"
      className="relative min-h-screen pt-8 px-4 lg:pt-16 lg:px-8 flex flex-col items-center justify-center text-white bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${bgImagePath})`, backgroundPosition: 'center top' }}
    >
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">Tokenomics</h2>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-8">
          {/* Card 1 */}
          <div className="card bg-gray-500 rounded-lg p-6 shadow-lg" style={{ height: '200px' }}>
            <div className="card-content text-center">
              <p className="text-5xl font-bold mb-2">90%</p>
              <p>Token Supply to The Liquidity Pools</p>
            </div>
          </div>
          {/* Card 2 */}
          <div className="card bg-gray-500 rounded-lg p-6 shadow-lg" style={{ height: '200px' }}>
            <div className="card-content text-center">
              <p className="text-5xl font-bold mb-2">10%</p>
              <p>Token Supply to Creators</p>
            </div>
          </div>
        </div>
        <h3 className="text-2xl font-bold text-center mt-8 mb-4">ALL INFO Of Token:</h3>
        <div className="flex justify-center">
          <ul className="list-disc inline-block text-left text-lg font-semibold">
            <li>90% of the token will be distributed to liquidity pool.</li>
            <li>10% will be given to creators.</li>
            <li>Raised Sol: 50</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
