import React, { useState, useRef } from 'react';
import {Tilt} from 'react-tilt';
import backgroundImage from './lol.png'; // Make sure the path is correct
import catGif from './cat.gif'; // Import the GIF file
import backgroundMusic from './cat.mp3'; // Import the audio file

function Home() {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  };

  const headingStyle = {
    fontWeight: 'bold',
    fontSize: '4rem',
    color: 'white',
    marginBottom: '20px',
  };

  const gifCardStyle = {
    width: '300px',
    height: '500px',
    overflow: 'hidden',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.2s',
  };

  const playButtonStyle = {
    fontSize: '1.5rem',
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px',
    outline: 'none',
  };

  return (
    <div style={backgroundStyle}>
      <h1 style={headingStyle}>$$$Nigga_CAT</h1>
      <Tilt options={{ max: 25, scale: 1.1, speed: 400, glare: true, "max-glare": 0.5 }}>
        <div style={gifCardStyle}>
          <img src={catGif} alt="Cat GIF" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
      </Tilt>
      <button onClick={togglePlay} style={playButtonStyle}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      <audio ref={audioRef} src={backgroundMusic} loop hidden />
    </div>
  );
}

export default Home;
